import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'primereact/card'
import { Timeline } from 'primereact/timeline';
import juniorImage from './my_images/JuniorImage.jpg';
import kidImage from './my_images/KidImage.jpg'
import collegeImage from './my_images/collegeImg.jpg';
import gradImage from './my_images/grad.JPG';
import nationalImage from './my_images/natonal.jpeg';
import sobienieImage from './my_images/sobienie.jpeg';
import trophyImage from './my_images/trophies.jpg';
import proImage from './my_images/turnPro.JPG';
import seniorImage from './my_images/senior.jpg'
import './css/About.css'

import { useTranslation } from "react-i18next";
function About({ setActiveTab }) {

    const { t } = useTranslation();
    const events = [
        {
            status: `${t("Started playing golf")}`,
            date: '2009',
            color: "green",
            image: kidImage,
            key: 1,
            content: `${t("Started playing golf text")}`
        },
        {
            status: `${t("Competetive junior career")}`,
            date: '2012',
            color: 'orange',
            image: juniorImage,
            key: 2,
            content: `${t("Competetive junior career text")}`
        },
        {
            status: `${t("Polish Golf Team")}`, date: '2013', color: 'red', image: nationalImage, key: 3,
            content: `${t("Polish Golf Team text")}`
        },
        {
            status: 'Sobienie Królewskie G&CC', date: '2015', color: 'gold', image: sobienieImage, key: 4,
            content: `${t("Sobienie")}`
        },
        {
            status: `${t("Junior Accomplishments")}`, date: '', color: '#673AB7', image: trophyImage, key: 5,
            content: `${t("Junior Accomplishments text")}`
        },
        {
            status: `${t("Collegiate Golf")}`, date: '2019', color: 'blue', image: collegeImage, key: 6,
            content: `${t("Collegiate Golf text")}`
        },
        {
            status: `${t("Senior Accomplishments")}`, date: '', color: '#673AB7', image: seniorImage, key: 7,
            content: `${t("Senior Accomplishments text")}`
        },
        {
            status: `${t("Graduation")}`, date: '27/05/2023', color: 'yellow', image: gradImage, key: 8,
            content: `${t("Graduation text")}`
        },

        {
            status: `${t("Professional Golf")}`, date: '16/10/2023', color: '#607D8B', image: proImage, key: 9,
            content: `${t("Professional Golf text")}`
        }
    ];

    const [isMobile, setIsMobile] = useState(false);
    const cardsRef = useRef([]);
    useEffect(() => {
        // Initial check for mobile device on component mount
        setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        setActiveTab("About")
    }, []);



    const customizedMarker = (item) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
            </span>
        );
    };

    const customizedContent = (item) => {

        return (
            <Card title={item.status} subTitle={item.date}
                ref={(element) => (cardsRef.current[item.key] = element)}
                className={`card-animation`}
                style={{ width: isMobile ? "270px" : "100%" }}
            >
                {item.image && <img src={item.image} alt={item.name} width={isMobile ? 200 : 250}
                    style={{ boxShadow: "0 0 10px var(--primary-900)", borderRadius: "5px" }} className=" m-5 aboutCard" />}
                <p className='m-5'>{item.content}</p>
            </Card >
        );
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.3, // Adjust the threshold as needed
        };

        const handleIntersect = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                } else {

                    entry.target.classList.remove('visible');
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersect, options);

        cardsRef.current.forEach((card) => {
            observer.observe(card);
        });

        return () => {
            observer.disconnect();
        };
    }, [isMobile]);

    return (
        <div>
            {
                isMobile ? (<div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowX: 'hidden' }}>
                        <h1 className='text-bluegray-900 font-bold text-7xl m-10 '>MICHAŁ BARGENDA</h1>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', overflowX: 'hidden' }} >
                        <div className="card mt-10" style={{ width: "10%" }}>
                            <div className='timeline-container' >
                                {<Timeline value={events} align="left" marker={customizedMarker} content={customizedContent} />}
                            </div>
                        </div>
                    </div >
                </div>) : (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowX: 'hidden' }} >
                    <h1 className='text-bluegray-900 font-bold  text-7xl m-10 '>MICHAŁ BARGENDA</h1>
                    <div className="card m-10" style={{ maxWidth: "85%" }}>
                        <div className='timeline-container'>
                            {<Timeline value={events} align="alternate" marker={customizedMarker} content={customizedContent} />}
                        </div>
                    </div>

                </div >)
            }
        </div>

    );
}

export default About;
