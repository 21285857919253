import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { useNavigate } from 'react-router';
import aboutHomePic from './my_images/about_home.JPG';
import { useTranslation } from "react-i18next";


function AboutHome({ setActiveTab }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const header = (
        <Image src={aboutHomePic} pt={{ image: { style: { borderRadius: "5px 5px 1px 1px" } } }} />
    );
    const footer = (
        <div className="flex flex-wrap justify-content-end gap-2">
            <Button label={t("more")} icon="pi pi-user" style={{ opacity: 1 }} onClick={() => {
                navigate('/about');
                setActiveTab("About me");
            }} />
        </div>
    );

    return (
        <div className="card flex justify-content-center mt-5" >
            <Card
                title="Michał Bargenda"
                subTitle={t("about me")}
                footer={footer}
                header={header}
                className="md:w-25rem"
            >
                <p className="m-0">
                    {t("homebio")}
                </p>
            </Card>
        </div >
    );
}

export default AboutHome;
