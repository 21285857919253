import React, { useState, useEffect } from "react";
import "./css/Countdown.css";


import { useTranslation } from 'react-i18next';

function Countdown({ date }) {
    const COUNTDOWN_TARGET = new Date(date);

    const { t } = useTranslation();
    const getTimeLeft = () => {
        const totalTimeLeft = COUNTDOWN_TARGET - new Date();
        if (totalTimeLeft <= 0) {
            const days = 0;
            const hours = 0;
            const minutes = 0;
            const seconds = 0;
            return { days, hours, minutes, seconds };
        }
        const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
        const seconds = Math.floor((totalTimeLeft / 1000) % 60);
        return { days, hours, minutes, seconds };
    };
    const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(getTimeLeft());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    });

    return (
        <div className='countdown'>
            <div className='content'>
                {Object.entries(timeLeft).map((el) => {
                    const label = el[0];
                    const value = el[1];
                    return (
                        <div className='box' key={label}>
                            <div className='value'>
                                <span>{value}</span>
                            </div>
                            <span className='label'> {t(`${label}`)} </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Countdown;