import React from 'react';
import './css/Partners.css'

function Footer({ activeTab }) {
    console.log(activeTab)
    return (
        <footer style={{
            backgroundColor: "var(--surface-0)", width: "100%", height: "35px", display: "flex", justifyContent: "center",
            alignItems: "center", position: (activeTab === "Partners" || activeTab === "Coaches") ? "fixed" : "", bottom: 0
        }}>
            <div style={{ fontSize: "9px" }}>
                Designed and developed by Michal Bargenda
            </div>
        </footer >
    );
}

export default Footer;
