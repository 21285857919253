import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card'
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview'
import 'primeflex/primeflex.css';
import Countdown from './Countdown';
import { DataContext } from './DataContext';

import { useTranslation } from 'react-i18next';
function HomeTournaments({ scrollPosition, setActiveTab }) {
    const [upcomingTournaments, setUpcomingTournaments] = useState([])
    const [pastTournaments, setPastTournaments] = useState([])
    const [isMobile, setIsMobile] = useState(false);
    const { pastTournamentsArr, upcomingTournamentsArr } = useContext(DataContext)
    const navigate = useNavigate();
    const { t } = useTranslation();
    useEffect(() => {
        let upcomingTmp = [upcomingTournamentsArr[0]]
        setUpcomingTournaments(upcomingTmp);
        let pastTmp = [pastTournamentsArr[0]]
        setPastTournaments(pastTmp)
        setIsMobile(window.innerWidth <= 768);
    }, [pastTournamentsArr, upcomingTournamentsArr])

    const pastFooter = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button label={t("details")} icon="pi pi-info-circle" onClick={() => window.open(pastTournaments[0].details, '_blank')} />
        </div >
    )

    const upcomingFooter = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button label={t("details")} icon="pi pi-info-circle" onClick={() => window.open(upcomingTournaments[0].details, '_blank')} />
        </div >
    )

    const tournamentsFooter = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button label={t("more")} icon="pi pi-calendar" className="p-button-outlined p-button-secondary" onClick={() => {
                navigate('/schedule');
                setActiveTab("Schedule");
            }} />
        </div >
    )

    const tournamentUpcomingTemplate = (tournament) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={tournament.image} alt={tournament.name} />
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div className="text-2xl font-bold text-900">{tournament.name}</div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-calendar"></i>
                                    <span className="font-semibold">{tournament.date}</span>
                                </span>
                            </div>
                            <Button link icon="pi pi-link" label={t("livescoring")} onClick={() => window.open(tournament.livescoring, '_blank')} />
                        </div>
                    </div>
                </div>
                <Countdown date={tournament.start} style={{ margin: "5px" }} />
            </div>
        )
    }

    const tournamentPastTemplate = (tournament) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={tournament.image} alt={tournament.name} />
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div className="text-2xl font-bold text-900">{tournament.name}</div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-calendar"></i>
                                    <span className="font-semibold">{tournament.date}</span>
                                </span>
                            </div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-align-left"></i>
                                    <span className="font-semibold">{tournament.place}</span>
                                </span>
                            </div>
                            <Button link icon="pi pi-link" label={t("results")} onClick={() => window.open(tournament.livescoring, '_blank')} />

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const calculatePastOffset = (offset) => {
        const maxOffset = 20; // Define the maximum offset for the image animation
        const imageOffset = Math.min(offset / 60, maxOffset);// Adjust the division factor for desired speed
        return `translateX(-${maxOffset - imageOffset}%)`;
    };
    const calculateUpcomingOffset = (offset) => {
        const maxOffset = 20; // Define the maximum offset for the image animation
        const imageOffset = Math.min(offset / 60, maxOffset); // Adjust the division factor for desired speed
        return `translateX(${maxOffset - imageOffset}%)`;
    };

    const calculatePastMobileOffset = (offset) => {
        const maxOffset = 60; // Define the maximum offset for the image animation
        const imageOffset = Math.min((offset) / 20, maxOffset);// Adjust the division factor for desired speed
        return `translateX(-${maxOffset - imageOffset}%)`;
    };
    const calculateUpcomingMobileOffset = (offset) => {
        const maxOffset = 60; // Define the maximum offset for the image animation
        const imageOffset = Math.min((offset) / 20, maxOffset); // Adjust the division factor for desired speed
        return `translateX(${maxOffset - imageOffset}%)`;
    };


    return (
        <div>
            {
                isMobile ? (
                    <Card title={t("tournaments")}
                        footer={tournamentsFooter}
                        className="card"
                    >
                        <div className="card ">
                            <Card title={t("recent")} footer={pastFooter} style={{ width: "100%", transform: calculatePastMobileOffset(scrollPosition), transition: 'transform 0.1s ease' }}>
                                <DataView value={pastTournaments} itemTemplate={tournamentPastTemplate} />
                            </Card>
                            <Divider />
                            <Card title={t("upcoming")} footer={upcomingFooter} style={{ width: "100%", transform: calculateUpcomingMobileOffset(scrollPosition), transition: 'transform 0.1s ease' }}>
                                <DataView value={upcomingTournaments} itemTemplate={tournamentUpcomingTemplate} />
                            </Card>
                        </div>
                    </Card >
                ) : (
                    <Card title={t("tournaments")} footer={tournamentsFooter}
                        className="card flex justify-content-center"
                    >
                        <div className="card flex justify-content-center">
                            <Card title={t("recent")} footer={pastFooter} style={{ width: "50%", transform: calculatePastOffset(scrollPosition), transition: 'transform 0.1s ease', }}>
                                <DataView value={pastTournaments} itemTemplate={tournamentPastTemplate} />
                            </Card>
                            <Divider layout="vertical" />
                            <Card title={t("upcoming")} footer={upcomingFooter} style={{ width: "50%", transform: calculateUpcomingOffset(scrollPosition), transition: 'transform 0.1s ease', }}>
                                <DataView value={upcomingTournaments} itemTemplate={tournamentUpcomingTemplate} />
                            </Card>
                        </div>
                    </Card >
                )
            }
        </div>

    );
}

export default HomeTournaments;
