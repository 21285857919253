// AppRoutes.js
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Navbar from './components/Navbar';
import Sponsors from './components/Sponsors';
import Coaches from './components/Coaches';
import Schedule from './components/Schedule';
import { DataProvider } from './components/DataContext';
import { ScrollTop } from 'primereact/scrolltop';
import Footer from './components/Footer';
import Media from './components/Media';

const AppRoutes = () => {
    const [activeTab, setActiveTab] = useState("Home");

    return (

        <BrowserRouter >
            <Navbar activeTab={activeTab} />
            <DataProvider>
                <Routes>
                    <Route path="/" element={<Home setActiveTab={setActiveTab} />} />
                    <Route path="/about" element={<About setActiveTab={setActiveTab} />} />
                    <Route path="/partners" element={<Sponsors setActiveTab={setActiveTab} />} />
                    <Route path="/coaching-staff" element={<Coaches setActiveTab={setActiveTab} />} />
                    <Route path="/schedule" element={<Schedule setActiveTab={setActiveTab} />} />
                    <Route path="/media" element={<Media setActiveTab={setActiveTab} />} />
                </Routes>
            </DataProvider>
            <ScrollTop className='bg-primary' />
            <Footer activeTab={activeTab} />
        </BrowserRouter>

    );
};

export default AppRoutes;
