import { useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataContext } from './DataContext';
import { Card } from 'primereact/card';

import './css/Media.css'
function Media({ setActiveTab }) {

    const { media } = useContext(DataContext)
    useEffect(() => {
        setActiveTab("Media")
    }, [])

    const mediaTitle = (media) => {
        return (
            <Button link className="media-name" onClick={() => window.open(media.link, '_blank')} label={media.name} />
        )
    }
    return (
        <div className="media-container mt-3 ">
            {media?.map((media, index) => (
                <Card key={index} className={`m-4 card-media media-item`} title={mediaTitle(media)} subTitle={media.date}>
                    <img
                        src={media.image}
                        alt={media.name}
                        className="media-image"
                        onClick={() => window.open(media.link, '_blank')}
                    />
                </Card>
            ))}
            <style jsx>{`
             .media-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            overflow-x: hidden;
        }
                .media-item {
                    text-align: center;
                }

                .media-image {
                    max-width: 100%;
                    height: auto;
                    max-height: 150px; /* Adjust the max-height as needed */
                    margin: 0 auto;
                }

                .media-name {
                    font-size: 1.3rem;

                }
            `}</style>
        </div>
    );
}

export default Media;
