import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import { DataView } from 'primereact/dataview';
import './css/Partners.css'
import { DataContext } from './DataContext';
import { useTranslation } from 'react-i18next';


function Sponsors({ setActiveTab }) {
    const { t } = useTranslation();
    const [partners, setPartners] = useState([]);
    const [strategic, setStrategic] = useState(null)
    const { partnersArr, strategicPartner } = useContext(DataContext)
    useEffect(() => {
        setPartners(partnersArr);
        setStrategic(strategicPartner)
        setActiveTab("Partners")
    }, [partnersArr, strategicPartner]);


    const partnerItem = (sponsor) => {
        return (
            <div className="col-6 sm:col-6 lg:col-4 xl:col-3 p-1 flex align-items-center justify-content-center "  >
                <div className="p-1 surface-card ">
                    <div className="flex flex-column align-items-center gap-2 py-5">
                        <img className="sponsor-img w-9 sm:w-13rem xl:w-12rem shadow-2 block xl:block mx-auto border-round" src={sponsor.image} alt={sponsor.name} onClick={() => window.open(sponsor.link, '_blank')} />
                        <Button className="text-1xl font-bold" link label={sponsor.name} onClick={() => window.open(sponsor.link, '_blank')} />
                    </div>
                </div>
            </div>
        );
    };
    const strategicItem = () => {
        if (strategic) {
            return (
                <div className="col-12  p-1" >
                    <div className="p-1 surface-card ">
                        <div className="flex flex-column align-items-center gap-2 py-5">
                            <img className="sponsor-img w-9 sm:w-23rem xl:w-26rem shadow-2 block xl:block mx-auto border-round" src={strategic.image} alt={strategic.name} onClick={() => window.open(strategic.link, '_blank')} />
                            <Button className="text-2xl font-bold" link label={strategic.name} onClick={() => window.open(strategic.link, '_blank')} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowX: 'hidden' }} className='mb-5'>
            <div style={{ margin: '20px', width: "85%" }}>
                <Card title={t("Strategic Partner")}>
                    {strategicItem()}
                </Card>
            </div>
            <div style={{ margin: '20px', width: "85%" }}>
                <Card title={t("partners")}>
                    <DataView className='sponsor-container' value={partners} itemTemplate={partnerItem} layout={'grid'} style={{ width: '100%' }}
                    />
                </Card>
            </div>
        </div >
    );
}

export default Sponsors;
