import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import './css/Coaches.css'
import { Button } from 'primereact/button';
import { useTranslation } from "react-i18next";
function Coaches({ setActiveTab }) {
    const [coaches, setCoaches] = useState([]);

    const { t } = useTranslation();
    useEffect(() => {
        setActiveTab("Coaches")
    }, [])
    useEffect(() => {
        const initialCoaches = [
            {
                image: '',
                name: 'Jacek Person',
                position: `${t("Golf Coach")}`,
            },
            {
                image: '',
                name: 'Jakub Surmacz',
                position: `${t("Strength")}`,
            },
            {
                image: '',
                name: 'Michał Mierzwa',
                position: `${t("Manager")}`,

            },
            {
                image: '',
                name: 'Waldemar Białowąs',
                position: `${t("Promoter")}`,
                email: "bialowas@vp.pl"
            },
            {
                image: '',
                name: 'Marek Bargenda',
                position: `${t("Director")}`,
                email: "markb@interia.eu"
            },
            {
                image: '',
                name: 'Jakub Budaj',
                position: `${t("Short game")}`,
            },
            {
                image: '',
                name: 'Dariusz Nowicki',
                position: `${t("Psychologist")}`,
            },
        ];

        setCoaches(initialCoaches);
    }, [t]);

    function contact(coach) {
        if (coach.email)
            return (
                <Button link label={coach.email}
                    pt={{
                        root: { style: { padding: '0px' } }
                    }}
                    onClick={() => window.open(`mailto:${coach.email}`, '_blank')}
                />
            )
    }

    return (
        <div style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'row', alignItems: 'center', overflowX: 'hidden' }} className="coach-container mt-3 ">
            {coaches.map((coach, index) => (
                <Card key={index} className={`m-6 card-coach`} title={coach.name}
                    subTitle={<div dangerouslySetInnerHTML={{ __html: coach.position }} />}
                    pt={{ content: { style: { padding: "0px" } } }} >
                    {contact(coach)}
                </Card>
            ))
            }
            <style jsx>{`
             .coach-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            overflow-x: hidden;
        }
            `}</style>
        </div >
    );
}

export default Coaches;
