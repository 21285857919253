import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import pl from './pl.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend"

const options = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' },

    // optional conversion function to use to modify the detected language code
    convertDetectedLanguage: 'Iso15897',
    convertDetectedLanguage: (lng) => lng.split('-')[0]
}



i18n.use(XHR).use(LanguageDetector).use(initReactI18next).init({
    //lng: 'pl',
    fallbackLng: 'en',
    detection: options,
    resources: {
        pl: pl,
        en: en,
    },
    interpolation: {
        escapeValue: false // react already safes from xss
    },
    react: {
        useSuspense: false
    }
});




export default i18n;