import React from 'react';
//theme
import "primereact/resources/themes/lara-dark-blue/theme.css";
//core
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import './App.css'
import AppRoutes from './AppRoutes';
import "./i18n";
function App() {

  return (
    <AppRoutes />
  );
}

export default App;
