import React, { useState, useEffect, useContext } from 'react';
import { Carousel } from 'primereact/carousel';
import { Card } from 'primereact/card'
import { useTranslation } from 'react-i18next';
import { DataContext } from './DataContext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
function SponsorsCarousel({ setActiveTab }) {
    const navigate = useNavigate();
    const [sponsors, setSponsors] = useState([]);
    const { t } = useTranslation();
    const { sponsorsArr } = useContext(DataContext)
    useEffect(() => {
        setSponsors(sponsorsArr);
    }, [sponsorsArr]);

    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const partnerTemplate = (sponsor) => {
        return (
            <div className="sponsor-item  flex align-items-center justify-content-center flex-column">
                <h1 className='text-1xl font-bold text-900 mb-5'>{sponsor.type}</h1>
                <img
                    src={sponsor.image}
                    alt={sponsor.name}
                    className="sponsor-image"
                    onClick={() => window.open(sponsor.link, '_blank')}
                />
                <Button link onClick={() => window.open(sponsor.link, '_blank')} label={sponsor.name} />
            </div>
        );
    }
    const strategicPartnerTemplate = (sponsor) => {
        return (
            <div className="sponsor-item  flex align-items-center justify-content-center flex-column">
                <h1 className='text-2xl font-bold text-900 mb-5'>{sponsor.type}</h1>
                <img
                    src={sponsor.image}
                    alt={sponsor.name}
                    className="sponsor-image "
                    onClick={() => window.open(sponsor.link, '_blank')}
                />
                <Button className="text-2xl" link onClick={() => window.open(sponsor.link, '_blank')} label={sponsor.name} />
            </div>
        );
    }

    const sponsorTemplate = (sponsor) => {
        if (sponsor.type === "Partner") {
            return partnerTemplate(sponsor)
        }
        else {
            return strategicPartnerTemplate(sponsor)
        }
    };
    const footer = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button label={t("more")} icon="pi pi-fw pi-briefcase" className="p-button-outlined p-button-secondary" onClick={() => {
                navigate('/partners');
                setActiveTab("Partners");
            }} />
        </div >
    )
    return (
        <Card title={t("partners")} footer={footer} className="card">
            <Carousel
                value={sponsors}
                numVisible={2}
                circular
                itemTemplate={sponsorTemplate}
                responsiveOptions={responsiveOptions}
                pt={{
                    item: { className: "flex adjust-items-center justify-content-center" },
                    itemCloned: { className: "flex adjust-items-center justify-content-center" }
                }}
            />
            <style jsx>{`
                .sponsor-item {
                    text-align: center;
                    padding: 2rem;
                }

                .sponsor-image {
                    max-width: 100%;
                    height: auto;
                    max-height: 200px; /* Adjust the max-height as needed */
                    margin: 0 auto;
                }

                .sponsor-name {
                    margin-top: 1rem;
                }
            `}</style>
        </Card>
    );
}

export default SponsorsCarousel;
